.web-header {
  img {
    max-width: 100%;
    width: 100vw;
    height: auto;
    position: relative;
  }
  .web-header-text {
    background-image: linear-gradient(#ffffff, #a4e6ff, white);

    position: absolute;
    text-align: center;
    top: 65%;
    right: 20%;
    left: 20%;
    padding-top: 50px;
    padding-bottom: 68px;
    padding-left: 20px;
    padding-right: 20px;
    // box-shadow: 0px 0px 23px #f8f9fa;
    border: 1px solid white;
    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
    h1 {
      padding-left: 20px;
      padding-right: 0px;
      // text-shadow: 0px 0px 2px #000000;

      border-radius: 10px;
      color: #000000;
      font-size: 35px;
      text-align: center;
    }
    @media screen and(max-width:1126px) {
      top: 56%;

      right: 10%;
      left: 10%;
      h1 {
        padding-left: 48px;
        padding-right: 48px;
        font-size: 30px;
      }
      button {
        margin-top: 2vh;
      }
    }
    @media screen and(max-width:1441px) {
      top: 60%;
    }
    @media screen and(max-width:1025px) {
      top: 45%;
    }
    @media screen and(max-width:769px) {
      top: 40%;
    }
    @media screen and(max-width:409px) {
      position: relative;
      clip-path: none;
      padding-top: 16px;
      padding-bottom: 9px;
      right: 0;
      left: 0;
      top: 32%;
    }
    @media screen and(max-width:426px) {
      position: relative;
      clip-path: none;
      padding-top: 16px;
      padding-bottom: 9px;
      right: 0;
      left: 0;
      top: 32%;
      h1 {
        font-size: 18px;
      }
    }
  }
  button {
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    &:hover {
      box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
      background-color: #ffffff; /* Green */
      border-radius: 10px;
      color: #4caf50;
      transform: scale(1.5);
    }
  }
}
.web-picture {
  h3 {
    font-size: 17px !important;
  }
  text-align: center;
  .web-picture-text {
    text-align: center;
    h1 {
      font-size: 2.5rem;
      margin-top: 10vh;
      @media screen and(max-width:769px) {
        font-size: 1.5rem;
      }
    }
  }
  .ek {
    text-align: center;

    h1 {
      font-size: 28px;
      margin-top: 5vh;
      text-align: start;
      padding: 10px;
      color: #ffffff;
      background-color: #004858b3;
      box-shadow: 8px 8px 2px 0px #000000c7;
      @media screen and(max-width:769px) {
        font-size: 1rem;
      }
    }
    .card {
      display: inline-block;
      background-color: #ffffff;
      margin: 24px;
      padding: 16px;
      width: 312px;
      min-width: 288px;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      transition: 0.4s;
      @media screen and(max-width:400px) {
        width: 275px;
        min-width: auto;
      }
    }

    .card:hover {
      box-shadow: none;

      .card-image {
        transform: translateX(0px);
        box-shadow: none;
      }
    }

    .card-image {
      width: 100%;
      height: 256px;
      border-radius: 8px;
      position: relative;
      transform: translateX(-32px);
      box-shadow: 16px 4px 24px 0 rgba(0, 0, 0, 0.2);
      transition: 0.4s;
      object-fit: cover;
    }

    .card-body {
      padding: 8px;
    }

    .card-title {
      font-size: 32px;
      margin: 12px 0;
      transition: 0.4s;
      font-family: "DM Serif Display", serif;
      font-weight: 600;
      letter-spacing: 2px;
    }

    .card-content {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
  .rat {
    h1 {
      margin-top: 5vh;
      text-align: start;
      font-size: 28px;
      padding: 10px;
      color: #ffffff;
      background-color: #004858b3;
      box-shadow: 8px 8px 2px 0px #000000c7;
      @media screen and(max-width:769px) {
        font-size: 1rem;
      }
    }
    text-align: center;
    .card {
      display: inline-block;
      background-color: #ffffff;
      margin: 24px;
      padding: 16px;
      width: 312px;
      min-width: 288px;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      transition: 0.4s;
      @media screen and(max-width:400px) {
        width: 275px;
        min-width: auto;
      }
    }

    .card:hover {
      box-shadow: none;

      .card-image {
        transform: translateX(0px);
        box-shadow: none;
      }
    }

    .card-image {
      width: 100%;
      height: 256px;
      border-radius: 8px;
      position: relative;
      transform: translateX(-32px);
      box-shadow: 16px 4px 24px 0 rgba(0, 0, 0, 0.2);
      transition: 0.4s;
      object-fit: cover;
    }

    .card-body {
      padding: 8px;
    }

    .card-title {
      font-size: 32px;
      margin: 12px 0;
      transition: 0.4s;
      font-family: "DM Serif Display", serif;
      font-weight: 600;
      letter-spacing: 2px;
    }

    .card-content {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
  .web-picture-button {
    padding: 50px;
    background-color: #000000db;
    h1 {
      color: rgb(255, 255, 255);
    }
    @media screen and(max-width:400px) {
      h1 {
        font-size: 23px;
      }
      padding: 25px;
      button {
        padding: 5px 14px !important;
      }
    }
    button {
      background-color: #4caf50; /* Green */
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      -webkit-transition-duration: 0.4s; /* Safari */
      transition-duration: 0.4s;
      &:hover {
        box-shadow: 0 12px 16px 0 rgba(255, 255, 255, 0.24),
          0 17px 50px 0 rgba(255, 255, 255, 0.19);
        background-color: #ffffff; /* Green */
        border-radius: 10px;
        color: #4caf50;
      }
    }
  }
}
.web-tools {
  padding-top: 15vh;
  padding-bottom: 4vh;
  text-align: center;
  .web-tools-text {
    h1 {
      text-align: center;
      padding-bottom: 10vh;
      font-size: 2.5rem;
      @media screen and (max-width: 400px) {
        font-size: 2rem;
      }
    }
  }

  .web-tools-picture {
    padding-top: 5vh;

    img {
      text-align: center;

      width: auto;
      height: 15vh;
    }
    h1 {
      text-align: center;
      padding-top: 2vh;
      padding-bottom: 1vh;
      font-size: 24px;
      @media screen and (max-width: 400px) {
        font-size: 20px;
      }
    }
    .web-tools-1 {
      padding-bottom: 10vh;
    }
    .web-tools-2 {
      padding-bottom: 10vh;
    }
    .web-tools-3 {
      padding-bottom: 10vh;
    }
    .web-tools-4 {
      padding-bottom: 10vh;
    }
    .web-tools-5 {
      padding-bottom: 10vh;
    }
    .web-tools-6 {
      padding-bottom: 10vh;
    }
  }
}
