.home-header {
  background-image: linear-gradient(#41b6c8, #44aad1, rgb(255, 255, 255));
  .text-header {
    margin-top: 50px;
    padding-bottom: 100px;
    h1 {
      text-align: center;
      margin-top: 50px;
      color: rgb(255, 255, 255);
      text-shadow: 0px 0px 5px rgb(51, 51, 51);
      font-size: 30px;
      letter-spacing: 0px;
    }
    p {
      text-align: center;
      color: white;
      margin: 5vh;
    }

    @media screen and (max-width: 1000px) {
      h1 {
        margin-top: 2vh;
        text-shadow: 0px 0px 5px #333333;
        font-size: 30px;
      }
      p {
        font-size: small;
      }
    }
    @media screen and (max-width: 425px) {
      h1 {
        margin-top: -3vh;
        text-shadow: 0px 0px 5px #333333;
        font-size: 14px;
      }
      p {
        font-size: 10px;
      }
    }
    animation: mymove 1s;
  }
  @keyframes mymove {
    from {
      top: 200px;
    }
    to {
      top: 0px;
    }
  }
  .home-header-buttion {
    text-align: center;
    button {
      border-radius: 10px;
      border: none;
      outline: 0;
      display: inline-block;
      font-size: 18px;
      padding: 5px 20px;
      color: rgb(255, 255, 255);
      background-color: #3778c234;
      text-align: center;
      box-shadow: 1px 1px 5px #ffffff;
      transition: transform 0.2s;
      &:hover {
        background-color: #ffffff;
        color: rgb(116, 116, 116);
        box-shadow: 0px 0px 10px rgb(255, 255, 255);
        box-shadow: inset 0px 0px 10px #ffffff;
        transform: scale(1.2);
      }
    }
  }

  img {
    max-width: 100vw;
  }
}
.home-project {
  .home-project-text {
    text-align: center;

    h1 {
      color: #00131b;
      margin-top: 5vh;
      font-size: 30px;
    }
    p {
      color: rgb(56, 56, 56);
      margin-bottom: 10vh;
    }
  }
  .home-project-1 {
    background-color: rgb(206 206 206 / 50%);
    text-align: center;
  }
  .card {
    display: inline-block;
    background-color: #ffffff;
    margin: 24px;
    padding: 16px;
    width: 312px;
    min-width: 288px;
    box-shadow: 9px 9px 12px -4px rgb(0 0 0 / 46%);
    border-radius: 8px;
    transition: 0.4s;
    @media screen and(max-width:400px) {
      width: 275px;
      min-width: auto;
    }
  }

  .card:hover {
    box-shadow: none;

    .card-image {
      transform: translateX(0px);
      box-shadow: none;
    }
  }

  .card-image {
    width: 100%;
    height: 256px;
    border-radius: 8px;
    position: relative;
    transform: translateX(-32px);
    box-shadow: 16px 4px 24px 0 rgba(0, 0, 0, 0.2);
    transition: 0.4s;
    object-fit: cover;
  }

  .card-body {
    padding: 8px;
  }

  .card-title {
    margin: 12px 0;
    transition: 0.4s;
    font-family: "DM Serif Display", serif;
    font-weight: 600;
    letter-spacing: 2px;
  }
  h3 {
    font-size: 17px;
    @media screen and(max-width:500px ) {
      font-size: 17px;
    }
    @media screen and(max-width:400px ) {
      font-size: 17px;
    }
  }

  .card-content {
    font-size: 14px;
    margin-bottom: 8px;
  }
}
.home-services {
  text-align: center;

  h1 {
    text-align: center;
    margin-top: 5vh;
    color: #868686;

    font-size: 32px;
    @media screen and(max-width:500px ) {
      font-size: 30px;
    }
    @media screen and(max-width:400px ) {
      font-size: 20px;
    }
  }
  .home-services-text {
    text-align: center;
    h1 {
      margin-top: 10vh;
      color: black;
      @media screen and(max-width:500px ) {
        font-size: 30px;
      }
      @media screen and(max-width:400px ) {
        font-size: 30px;
      }
    }
    p {
      margin-bottom: 10vh;
      @media screen and(max-width:400px ) {
        font-size: 14px;
        padding: 20px;
      }
    }
  }

  .home-services-scale {
    transition: all 0.2s;
    :hover {
      transform: scale(1.1);
      box-shadow: 0 19px 38px rgba(252, 252, 252, 0.3),
        10px 5px 22px rgba(255, 255, 255, 0.22);
    }
  }

  img {
    text-align: center;
    width: 30vh;
    height: auto;
    border-radius: 50%;
  }
}
.home-contact {
  text-align: center;
  width: auto;
  height: 40vh;
  margin-top: 13vh;
  background-color: #424b51;
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  h1 {
    color: white;
    text-align: center;
    padding: 9vh;
  }
  @media screen and (max-width: 765px) {
    h1 {
      font-size: 26px;
      padding: 4vh;
    }
    height: 30vh;
  }
  @media screen and (max-width: 426px) {
    h1 {
      font-size: 20px;
      padding: 4vh;
    }
    height: 24vh;
  }
  .button {
    border: rgba(63, 201, 255, 0.644);
    border: 1px;
    border-radius: 5px 5px 5px 5px;
    color: rgb(46, 46, 46);
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;

    font-size: 24px;
    transition-duration: 0.4s;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    @media screen and (max-width: 426px) {
      font-size: 26px;
    }
    @media screen and (max-width: 326px) {
      font-size: 20px;
    }
  }

  .button1 {
    background-color: rgb(255, 255, 255);
    color: rgb(125, 149, 255);
  }

  .button1:hover {
    box-shadow: inset 0px 0px 5px rgb(15, 6, 6);
    transform: scale(1.1);
    background-color: #2b2b2b;
    color: white;
  }
}
