.Navbar-top {
  box-shadow: 8px -2px 10px #045174;
  background-color: #f8f9fa;
  .Collapse-1 {
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .Nav-icon {
    text-align: center;
    a {
      padding: 10px;
      color: #025073;

      &:hover {
        color: #28bee3;
        text-decoration: none;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    a {
      margin-top: 15px;
      margin-right: none;
    }
  }
  img {
    margin-left: 15px;
  }
}
