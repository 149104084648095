.header-about {
  padding: 8vh 0vh 0vh 0vh;
  text-align: center;
  background-size: cover;

  background-image: url(spmUM.gif);
  .header-about-text {
    .text-p {
      padding: 25px 20px 201px 30px;
      p {
        text-align: center;
        background-color: #00000069;
        padding: 10px;    font-weight: 200;
        color: white;
        box-shadow: 0px 0px 5px black;
        span {
          color: rgb(255, 255, 255);
          font-size: 24px; font-weight: 400;
        }
      }
    }
    h1 {
      color: #f8f9fa;
      font-size: 70px;
      letter-spacing: 2vw;
    }
  }
  .header-about-img-1 {
    position: relative;
    img {
      transition: transform 0.2s;
      width: 100px;
      height: auto;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
}
.about-title-text {
  .about-title-text-box {
    border: 2px solid rgba(255, 255, 255, 0.4);
    background-color: #cccccc7d;
    margin: 20px;
    padding: 20px;
    box-shadow: 0px -7px 20px -12px rgb(0 0 0 / 50%),
      0px 8px 20px -12px rgb(0 0 0 / 50%);
    transition: transform 0.2s;
    &:hover {
      box-shadow: 0px -7px 25px -12px rgb(0 0 0 / 50%),
        0px 8px 25px -12px rgb(0 0 0 / 50%);
    }
  }
  h3 {
    color: #444;
  }
  li {
    color: #444;
  }
}
